import { TaskType } from '../enums';

const taskTypeMapping = {
  [TaskType.JUDICIAL_ADMINISTRATION]: 'Administração Judicial',
  [TaskType.CALCULATION_ADJUSTMENT_3]: 'Adequação de Cálculo 3º',
  [TaskType.GR_CALCULATION_ADJUSTMENT]: 'Adequação de Cálculo GR',
  [TaskType.FINANCIAL_ANALYSIS]: 'Análise Financiamento',
  [TaskType.TCD_ANALYSIS]: 'Análise TCD',
  [TaskType.WORKING_HOURS_ACCOUNTING]: 'Apuração Banco Horas',
  [TaskType.UPDATE]: 'Atualização',
  [TaskType.UPDATE_AND_OPINION]: 'Atualização + Parecer',
  [TaskType.REAL_ESTATE_EVALUATION]: 'Avaliação Imobiliária',
  [TaskType.CALCULUS2500]: 'Cálculos + L. Ev. S-2500',
  [TaskType.CALCULATIONS_AND_OPINION]: 'Cálculos + Parecer',
  [TaskType.CALCULATIONS_AND_QUESTION]: 'Cálculos + Quesito',
  [TaskType.ACTUARIAL_CALCULATION]: 'Cálculo Atuarial',
  [TaskType.CHEQUE_SPECIAL_CALCULATIONS]: 'Cálculos Cheque Especial',
  [TaskType.COMPENSATION_CALCULATIONS]: 'Cálculos com Compensação',
  [TaskType.FGTS_CALCULATIONS]: 'Cálculos FGTS',
  [TaskType.LOAS_CALCULATIONS]: 'Cálculos LOAS',
  [TaskType.RESCISSION_CALCULATIONS]: 'Cálculos Rescisão',
  [TaskType.CALCULATIONS_AND_IMPUGNATION]: 'Cálculos + Impugnação',
  [TaskType.AGREEMENT_CALCULATIONS]: 'Cálculos Acordo',
  [TaskType.LIQUIDATION_CALCULATIONS]: 'Cálculos de Liquidação',
  [TaskType.REDUCED_LIQUIDATION_CALCULATIONS]: 'Cálculos de Liquidação e Reduzido',
  [TaskType.INSS_AND_IR_CALCULATIONS]: 'Cálculos INSS e IR',
  [TaskType.INSTRUCTION_CALCULATIONS]: 'Cálculos Instrução',
  [TaskType.CALCULATIONS_CONFERENCE]: 'Conferência de Cálculos',
  [TaskType.JUDGMENT_CONTINGENCY]: 'Contingência Acórdão',
  [TaskType.INITIAL_CONTINGENCY]: 'Contingência Inicial',
  [TaskType.SENTENCE_CONTINGENCY]: 'Contingência Sentença',
  [TaskType.DILIGENCE]: 'Diligência',
  [TaskType.CLARIFICATION]: 'Esclarecimento',
  [TaskType.INFLATIONARY_PURGE]: 'Expurgo Inflacionário',
  [TaskType.IMPUGNATION]: 'Impugnação',
  [TaskType.PERITIAL_HONORARIUM_IMPUGNATION]: 'Impugnação Honorários Periciais',
  [TaskType.REAL_ESTATE_IMPUGNATION]: 'Impugnação Imobiliária',
  [TaskType.CALCULATION_ENV_BAS_INFO]: 'Inf. Bas. d/Calc Env.',
  [TaskType.EVENT2500AND2501]: 'Lançamento Evento S-2500 E S-2501',
  [TaskType.TECHNICAL_OPINION]: 'Parecer Técnico',
  [TaskType.INSALUBRITY_EXPERTISE]: 'Perícia Insalubridade',
  [TaskType.INSTRUCTION_EXPERTISE]: 'Perícia Instrução',
  [TaskType.MEDICAL_EXPERTISE]: 'Perícia Médica',
  [TaskType.OFFICIAL_EXPERTISE]: 'Perícia Oficial',
  [TaskType.DANGEROUSNESS_EXPERTISE]: 'Perícia Periculosidade',
  [TaskType.ACCOUNTABILITY]: 'Prestação de Contas',
  [TaskType.PROPOSAL]: 'Proposta',
  [TaskType.MEDICAL_QUESTION]: 'Quesito Médico',
  [TaskType.QUESTIONS]: 'Quesitos',
  [TaskType.QUESTIONS_AND_IMPUGNATION]: 'Quesitos + Impugnação',
  [TaskType.REAL_ESTATE_QUESTIONS]: 'Quesitos Imobiliários',
  [TaskType.FINANCING_RECAL]: 'Recálculo Financiamento',
  [TaskType.MANAGERIAL_REPORT]: 'Relatório Gerencial',
  [TaskType.DOCUMENTATION_REQUEST]: 'Solicitação Documentação | Perícia',
  [TaskType.DILIGENCE_TERM]: 'Termo Diligência',
  [TaskType.IMPUGNATION_VIEW]: 'Vista à Impugnação',
  [TaskType.OTHERS]: 'Outros',
};

const getTaskTypeLabel = (taskType: TaskType) => taskTypeMapping[taskType];

export default getTaskTypeLabel;
